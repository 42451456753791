.bloglist {
    width: 100%;
    &-title {
        font-size: 2rem;
        margin: 2.66rem 0; 
        font-weight: 800;
        letter-spacing: 1px;

        @media only screen and (max-width: 570px) {
            font-size: 2.29rem;
            margin: 3rem 0;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    &-blog {
        border-top: 1px solid $text-color;
        &--title {
            padding: 2rem 0;
            font-size: 1.8rem;
            font-weight: 600;
            cursor: pointer;

            @media only screen and (max-width: 570px) {
                font-size: 2.29rem;
                padding: 2.29rem 0;
            }

            &:hover {
                text-decoration: underline;
                color: $color-blue;
            }
        }
    }

}
@mixin responsive-width-handler {
    @media only screen and (max-width: 1024px) {
        width: 85%;
    }

    @media only screen and (max-width: 810px) {
        width: 90%;
    }

    @media only screen and (max-width: 570px) {
        width: 90%;
    }
}
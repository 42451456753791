.welcome-section {
    width: 100%;
    margin: auto;
    background-color: #82c7cb;
    color: #28231c;
    padding: 1rem 0rem;
    font-size: 4rem;

    @media only screen and (max-width: 490px) {
        padding: 2rem 0rem;
    }
}

.homepage {
    width: 80%;
    margin: auto;
    font-size: 1.6rem;
    padding: 1rem 0;
}

.hero {
    &-section {
        padding: 4rem 0;
    }

    &-intro {
        margin-bottom: 0rem;
    }

    &-description {
        font-size: 2rem;
        color: black;
        display: block;
        margin-top: .3rem;
        margin-left: .3rem;
        margin-bottom: .3rem;

        @media only screen and (max-width: 480px) {
            width: 90%;
            font-size: 2.6rem;
        }    

        span {
            font-size: inherit;
            text-decoration: line-through;
            margin-left: .5rem;
        }
    }
}



.post-section {
    padding-top: 9rem;
    width: 100%;
    .post {
        width: 50%;
        margin: auto;
        color: $text-color;
        font-family: 'Source Serif Pro', serif;
        font-size: 1.8rem;
        letter-spacing: 1px;
        line-height: 1.6;

        @media only screen and (max-width: 1024px) {
            width: 60%;
        }

        @media only screen and (max-width: 810px) {
            width: 70%;
        }

        @media only screen and (max-width: 570px) {
            width: 80%;
        }

        @media only screen and (max-width: 490px) {
            line-height: 1.4;
            font-size: 2.43rem;
            width: 90%;
        }

        .date {
            font-size: 1.5rem;
            font-family: Montserrat, sans-serif;
            padding-bottom: 2rem;

            @media only screen and (max-width: 490px) {
                font-size: 2.14rem;
            }

            span {
                text-transform: lowercase;
                margin: 0 3px;
                margin-right: 1rem;

                @media only screen and (max-width: 490px) {
                    font-size: 2rem;
                    margin-right: .6rem;
                }
            }
        }

        h2 {
            margin-top: 2rem;
            margin: 1.5rem 0rem;

            @media only screen and (max-width: 490px) {
                margin-top: 5.4rem;
                margin-bottom: 2.3rem;
            }
        }

        h3 {
            margin: 1.2rem 0;
        }

        h4 {
            margin: 1rem 0;
            font-weight: 400;
        }

        hr {
            margin-bottom: 6rem;
        }

        p {
            margin: auto;
            letter-spacing: .7px;
            margin-bottom: 2.7rem;
            letter-spacing: 1px;
        }

        strong {
            line-height: 1.8;
            letter-spacing: 1.1px;
        }

        blockquote {
            padding: 0rem 2rem;
            font-weight: 600;
            font-style: italic;
            margin: 2rem 0;

            p {
                border-left: 1rem solid $color-blue;
                padding-left: 1.3rem;
                font-size: 2rem;

                @media only screen and (max-width: 490px) {
                    font-size: 2.6rem;
                }
            }
        }

        img {
            margin-left: auto;
            margin-right: auto;
            height: auto;
            max-width: 100%;
            display: block;
        }
        
        iframe {
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
            display: block;
            margin-bottom: 2.7rem;
        }

        ul, ol {
            list-style-position: inside;
            margin-bottom: 2rem;
            margin-left: 1rem;
        }

        ul {
            list-style: none;
            
            li {
                padding: 0 0 0 2.6rem;
                position: relative;

                @media only screen and (max-width: 490px) {
                    padding: 0 0 0 3rem;
                }
                &::before{
                    content: "";
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    left :0;
                    top: .4em;
                    border-radius: 50%;
                    background-color: $color-blue;
                }
            }
        }

        li {
            ul,ol {
                margin-left: 2rem;
            }
        }
    }
}
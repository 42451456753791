.header-section {
    
    border-bottom: 1px solid $text-color;
}

.header {
    width: 80%;
    margin: auto;
    font-size: 2rem;
    padding: 1.8rem 0;


    @media only screen and (max-width: 480px) {
        font-size: 2.57rem;
        padding: 2.3rem 0;
    }
}
* ,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
    font-size: 62.5%;

    @media only screen and (max-width: 1024px) {
        
    }

    @media only screen and (max-width: 810px) {
        
    }

    @media only screen and (max-width: 570px) {
         // 7px
    }

    @media only screen and (max-width: 490px) {
        font-size: 43.75%;
    }
}


body {
    font-family: Montserrat, sans-serif;
    padding: 0%;
    background-color: $bg-color;
    color: $text-color;
    box-sizing: border-box;
}

.new-section {
    width: 100%;
    padding: 2rem 0;

}

.container-1 {
    width: 80%;
    margin: auto;

    @include responsive-width-handler;
}

.container-2 {
    width: 75%;
    margin: auto;

    @include responsive-width-handler;
}

.col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    @media only screen and (max-width: 570px) {
        grid-template-columns: 1fr;
    }
}

.new-section {
    width: 100%;
}

li {
    text-align: -webkit-match-parent;
}

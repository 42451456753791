h1, h2, h3, h4, h5 {
    font-family: Montserrat, sans-serif;
}

h1 {
    margin: auto;
    font-size: 5.6rem;
    padding-bottom: 1rem;
    letter-spacing: 1px;

}

h2 {
    font-size: 2.6rem;
    @media only screen and (max-width: 490px) {
        font-size: 2.85rem;
    }
}

h3 {

}

h4 {

}

span {
    font-size: 1.4rem;

    @media only screen and (max-width: 490px) {
        font-size: 2rem;
    }
}